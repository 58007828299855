import React from 'react'

import { CSSReset, Help } from '@te-digi/styleguide'

import { CodeBox } from '../../components/CodeBox'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'

const CodeExample = `<Provider locale={locale}>
  <CSSReset />
  <App />
</Provider>`

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="CSSReset"
    components={[{ component: CSSReset }]}
  >
    <Section>
      <Help variant="error">
        Koska resetointi tapahtuu globaalisti, komponenttia tulee käyttää
        ainoastaan verkkopalvelun ulkopuolisissa sovelluksissa.
      </Help>
      <CodeBox language="tsx">{CodeExample}</CodeBox>
    </Section>
  </Content>
)

export default Page
